import { MERCHANTS_ACTIONS } from "../../constants/actions"

export const showForm = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_SHOW_FORM_REGISTER
  }
}

export const sentFilters = (filters, token, page, acquirerSelected) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_SENT_FILTER_LIST,
    filters,
    token,
    page,
    acquirerSelected
  }
}

export const receiveResponseResultFiltereds = (response) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_FILTER_LIST,
    response
  }
}

export const handleFilterValues = (id, value) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_HANDLE_VALUES_FILTERS,
    id,
    value
  }
}

export const nextPageBulk = () => {
  return {
      type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_BULK_LOAD_NEXT_PAGE
  }
}

export const goToPageBulk = (page) => {
  return {
      type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_BULK_LOAD_GO_TO_PAGE,
      page
  }
}

export const backPageBulk = () => {
  return {
      type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_BULK_LOAD_BACK_PAGE
  }
}

export const handleFilterBulkLoadValues = (id, value) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_HANDLE_VALUES_BULK_LOAD_FILTERS,
    id,
    value
  }
}

export const handleRegisterMerchantValues = (id, value) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_HANDLE_FIELDS_REGISTER_MERCHANT,
    id,
    value
  }
}

export const handleEcommerceCodetValues = (id, value, brandSelected) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_HANDLE_ECOMMERCE_CODE,
    id,
    value,
    brandSelected
  }
}

export const handleEditEcommerceCodetValues = (id, value, brandSelected) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_EDIT_HANDLE_ECOMMERCE_CODE,
    id,
    value,
    brandSelected
  }
}

export const resetValuesRegisterMerchant = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RESET_VALUES_REGISTER_MERCHANT
  }
}

export const showModalRegister = (value) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_SHOW_MODAL_REGISTER,
    value
  }
}

export const closeModalRegister = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_CLOSE_MODAL_REGISTER
  }
}

export const registerMerchant = (merchant, token, acquirerSelected) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_REGISTER_MERCHANT,
    merchant,
    token,
    acquirerSelected
  }
}

export const receiveResponseRegisterMerchant = (response) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_REGISTER_MERCHANT,
    response
  }
}

export const updateBrandSelected = (brand) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_BRAND_SELECTED,
    brand
  }
}

export const updateBrandEditSelected = (brand) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_EDIT_BRAND_SELECTED,
    brand
  }
}

export const updateCurrencySelected = (currency, position, currencies, element, brands) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_CURRENCY_SELECTED,
    currency,
    position,
    currencies,
    element,
    brands
  }
}

export const updateEditCurrencySelected = (currency, position, brand, allCurrencies) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_EDIT_CURRENCY_SELECTED,
    currency,
    position,
    brand,
    allCurrencies
  }
}

export const updateFilters = (filters, acquirerSelected) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_FILTERS,
    filters,
    acquirerSelected
  }
}

export const nextPage = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_NEXT_PAGE
  }
}

export const backPage = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_BACK_PAGE
  }
}

// NEW FEATURE REGISTER

export const updateNewRegisterWithBrands = (allBrands, allCurrencies) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_GENERATE_BRANDS_INITIAL,
    allBrands,
    allCurrencies
  }
}
export const updateBrand = (newBrandUpdated) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_NEW_REGISTER_FIELDS,
    newBrandUpdated
  }
}

export const handleFile = (file) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_HANDLE_FILE,
    file
  }
}

export const getMerchantInfo = (brands, currencies, code, token, acquirerSelected) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_GET_MERCHANT_INFO,
    brands,
    currencies,
    code,
    token,
    acquirerSelected
  }
}

export const updateCurrenciesEditMerchant = (id, token, acquirerSelected) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_CURRENCIES_EDIT_MERCHANT,
    id,
    token,
    acquirerSelected
  }
}

export const receiveResponseUpdateCurrenciesEditMerchant = (response) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_UPDATE_CURRENCIES_EDIT_MERCHANT,
    response,
  }
}
export const getReceiveResponseMerchantInfo = (response) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_GET_MERCHANT_INFO,
    response
  }
}

export const handleValuesUpdateMerchant = (id, value) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_HANDLE_FIELDS_UPLOAD_MERCHANT,
    id,
    value
  }
}

export const receiveResponseprocessFile = (response) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_PUT_BATCH,
    response
  }
}

export const putProcessFile = (token, id) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_PUT_BATCH,
    token,
    id
  }
}

export const resetEditValues = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RESET_EDIT_MERCHANT_VALUES
  }
}

export const editMerchant = (editedMerchant, token, merchantCode, acquirerSelected, currencies) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_EDIT_MERCHANT,
    editedMerchant,
    token,
    merchantCode,
    acquirerSelected,
    currencies
  }
}

export const receiveResponseEditMerchant = (response) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_EDIT_MERCHANT,
    response
  }
}

export const cleanAllFields = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_CLEAN_ALL_FILTERS
  }
}
// BULK LOAD

export const resetValuesBulkLoad = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RESET_VALUES_BULK_LOAD
  }
}

export const uploadFile = (token, file, fields, acquirerSelected, nameFile) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPLOAD_FILE,
    token,
    file,
    fields,
    acquirerSelected,
    nameFile
  }
}

export const receiveResponseUploadFile = (response) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_UPLOAD_FILE,
    response
  }
}

export const increasePercent = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_INCREASE_PERCENT,
    quantity: 1
  }
}

export const resetValues = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RESET_VALUES
  }
}

export const getUrlUploadFile = (token, acquirerSelected, nameFile) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_GET_URL_UPLOAD_FILE,
    token,
    acquirerSelected,
    nameFile
  }
}

export const receiveResponseGetUrlUploadFile = (response) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_GET_URL_UPLOAD_FILE,
    response
  }
}

export const sentFileToServer = (url, fields, file) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_SENT_FILE_TO_SERVER,
    url,
    fields,
    file
  }
}

export const receiveResponseSentFileToServer = (response) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_SENT_FILE_TO_SERVER,
    response
  }
}

export const sentAllFilters = (filters, token, page, acquirerSelected) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_SENT_ALL_FILTERS,
    filters,
    token,
    page,
    acquirerSelected
  }
}

export const receiveResponseSentALlFilters = (response) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_SENT_ALL_FILTERS,
    response
  }
}

export const updatePercent = (percent) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_PERCENT_LOADED,
    percent
  }
}

export const disabledButtonUpload = (value) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_DISABLED_BUTTON_UPLOAD,
    value
  }
}

export const generateUrlToDownloadFile = (id, token, acquirerSelected) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_GENERATE_URL_DOWNLOAD_FILE,
    id,
    token,
    acquirerSelected
  }
}

export const receiveResponseGenerateUrlToDownloadFile = (response) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_GENERATE_URL_DOWNLOAD_FILE,
    response
  }
}


export const downloadFile = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_DOWNLOAD_FILE,
  }
}

export const receiveResponseDownloadFile = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_DOWNLOAD_FILE
  }
}

export const resetMerchantFiltersVales = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RESET_VALUES_MERCHANTS_FILTERS,
  }
}

export const deleteFilterSelected = (filters, token, page, acquirerSelected, filterSelectedToDelete, propertyName) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_DELETE_FILTERS,
    filters,
    token,
    page,
    acquirerSelected,
    filterSelectedToDelete,
    propertyName
  }
}

export const deleteBulkLoadFilterSelected = (filters, token, page, acquirerSelected, filterSelectedToDelete, propertyName) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_BULK_LOAD_DELETE_FILTERS,
    filters,
    token,
    page,
    acquirerSelected,
    filterSelectedToDelete,
    propertyName
  }
}

export const resetCorrectFormat = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RESET_CORRECT_FORMAT,
  }
}

export const closeModalEditMerchant = () => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_CLOSE_MODAL_EDIT_MERCHANT,
  }
}

export const updateAllBodyMerchantEdit = (body, currencies) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_EDIT_UPDATE_ALL_BODY,
    body,
    currencies
  }
}

export const updateAllBodyMerchantNew = (body, currencies) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_BODY_NEW_MERCHANT,
    body,
    currencies
  }
}

export const goToPage = (filters, page, token, acquirerSelected) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_GO_TO_PAGE,
    filters,
    page,
    token,
    acquirerSelected
  }
}

export const changeOrder = (field, direction, token, acquirerSelected, page, filters) => {
  return {
    type: MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_CHANGE_ORDER,
    field,
    direction,
    filters,
    token,
    page,
    acquirerSelected
  }
}