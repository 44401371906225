import { MERCHANTS_ACTIONS } from "../../constants/actions";
import { defaultValuesMerchants } from "../../constants/constants";

let defaultState = defaultValuesMerchants;

const merchants = (state = defaultState, action) => {
  switch (action.type) {
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_SHOW_FORM_REGISTER:
      return {
        ...state,
        showFormRegister: !state.showForm
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_SENT_FILTER_LIST: {
      // console.log(state.pageCurrent)
      // console.log(action)
      return {
        ...state,
        responseResultFilters: null,
        merchantsLoaded: false
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_FILTER_LIST:
      // console.log("MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_FILTER_LIST")
      return {
        ...state,
        responseResultFilters: action.response,
        merchantsLoaded: true,
        changePage: false
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_HANDLE_VALUES_FILTERS: {
      const aux = { ...state.filter };
      aux[action.id] = action.value;

      return {
        ...state,
        filter: aux
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_HANDLE_FIELDS_REGISTER_MERCHANT: {
      const aux = { ...state.newRegister };

      aux[action.id] = action.value;
      return {
        ...state,
        newRegister: aux
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_HANDLE_FIELDS_UPLOAD_MERCHANT: {
      const aux = { ...state.responseGetMerchantInfo };
      // console.log(action.value)
      // console.log(action.id)
      // console.log(state.responseGetMerchantInfo)
      if (action.id === "merchant_information") {
        const splitCountry = action.value.split("-")
        const obj = {
          "country_name": splitCountry[0],
          "country_number": splitCountry[1],
          "country_code": splitCountry[2]
        }
        console.log(obj)
        aux.merchant_information = obj;
        return {
          ...state,
          responseGetMerchantInfo: aux
        }
      }
      if (action.id === "protocolo") {
        const splitProtocolo = action.value.split("-")
        const obj = {
          "code": splitProtocolo[0],
          "version": splitProtocolo[1]
        }
        aux.protocol = obj;
        return {
          ...state,
          responseGetMerchantInfo: aux
        }
      }

      aux[action.id] = action.value;
      // console.log(aux)
      return {
        ...state,
        responseGetMerchantInfo: aux
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_HANDLE_ECOMMERCE_CODE: {
      const aux = { ...state.brandSelected };
      const auxAllCodes = { ...state.allCodes }
      const auxNewRegister = { ...state.newRegister };

      auxNewRegister.brands.forEach((brand) => {
        if (brand.position === action.brandSelected.position) {
          brand[action.id] = action.value
        }
        return brand;
      })
      return {
        ...state,
        brandSelected: aux,
        allCodes: auxAllCodes,
        newRegister: auxNewRegister
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_EDIT_HANDLE_ECOMMERCE_CODE: {
      const aux = { ...state.brandSelected };
      const auxAllCodes = { ...state.allCodes }
      const auxNewRegister = { ...state.responseGetMerchantInfo };

      auxNewRegister.brandsAux = auxNewRegister.brandsAux.map((brand, index) => {
        if (brand.position === action.brandSelected.position) {
          brand[action.id] = action.value
        }
        return brand;
      })
      return {
        ...state,
        brandSelected: aux,
        allCodes: auxAllCodes,
        responseGetMerchantInfo: auxNewRegister
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_BRAND_SELECTED: {
      const aux = { ...state.brandSelected };
      const auxNewRegisterUpdate = { ...state.newRegister };
      aux.code = action.brand.code;
      aux.name = action.brand.name;
      aux.position = action.brand.position;
      aux.enabled = action.brand.enabled;

      return {
        ...state,
        brandSelected: aux,
        newRegister: auxNewRegisterUpdate
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_EDIT_BRAND_SELECTED: {
      const aux = { ...state.responseGetMerchantInfo };
      // console.log(aux.brandsAux)
      // console.log(action.brand)
      // action.newBrandsUpdated
      aux.brandsAux.map((brand, index) => {
        if (brand.position === action.brand.position) {
          if (brand.enabled)
            brand.enabled = false;
          else
            brand.enabled = true;
        }
        return brand;
      })
      return {
        ...state,
        responseGetMerchantInfo: aux
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_CURRENCY_SELECTED: {
      const auxNewRegister = { ...state.newRegister }
      auxNewRegister.brands.forEach((brand) => {
        if (brand.position === action.position) {
          brand.currencies.forEach(curren => {
            if (curren.iso_code === action.currency.iso_code && curren.numeric_code === action.currency.numeric_code) {
              if (curren.enabled === "true") {
                curren.enabled = "false";
              } else
                curren.enabled = "true";
            }
            return curren;
          })
        }
        return brand;
      })

      return {
        ...state,
        newRegister: auxNewRegister,
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_EDIT_CURRENCY_SELECTED: {
      // console.log(action)
      let auxNewRegister = { ...state.responseGetMerchantInfo }
      const auxBrandSelected = { ...action.brand };
      const auxCurrencySelected = { ...action.currency };
      let currenciesSelecteds = [...state.listCurrenciesSelecteds]
      let obj = {}
      let list = []

      // console.log(auxBrandSelected.currencies)
      // console.log(auxCurrencySelected)
      auxBrandSelected.currencies.forEach(curr => {
        if (curr.iso_code === auxCurrencySelected.iso_code && curr.numeric_code === auxCurrencySelected.numeric_code) {
          console.log(curr, 'moneda encontrada para actualizar')
          obj = { enabled: (curr.enabled || curr.enabled === 'true') ? false : 'true', iso_code: curr.iso_code, numeric_code: curr.numeric_code, description: auxCurrencySelected.description }
          console.log(curr, 'aqui1')
        } else {
          console.log(curr, 'aqui2')
          obj = curr
        }
        list.push(obj)
      })
      // console.log(list)


      // ACTUALIZO EL BRAND
      // console.log(auxNewRegister)
      auxNewRegister.brandsAux.map(brand => {
        if (brand.enabled === false) {
          brand.currencies = brand.currencies.map(curren => {
            curren.enabled = 'false';
            return curren
          })
          return brand;
        }
        else {
          if (brand.code === auxBrandSelected.code && brand.name === auxBrandSelected.name) {
            brand.currencies = list
          }
          return brand;
        }
      })
      // console.log(auxNewRegister)
      return {
        ...state,
        responseGetMerchantInfo: auxNewRegister,
        currencySelectedByEdit: action.currency,
        listCurrenciesSelecteds: currenciesSelecteds
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_REGISTER_MERCHANT:
      return {
        ...state,
        responseRegisterNewMerchantIsLoaded: true
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_REGISTER_MERCHANT: {
      // console.log(action)
      return {
        ...state,
        responseRegisterNewMerchant: action.response,
        responseRegisterNewMerchantIsLoaded: action.response.success
      }
    }
    // NEW REGISTER ACTIONS
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_SHOW_MODAL_REGISTER:
      return {
        ...state,
        showModalStatusRegister: action.value
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RESET_VALUES_REGISTER_MERCHANT: {
      const aux = {
        commerce_name: '',
        merchant_category_code: '',
        merchant_information: {
        },
        country: 'PER',
        notification_url: '',
        protocol: {
        },
        version_protocol: '',
        brands: [],
        date_registry: "",
        date_update: "",
        last_update_by: "",
        decoupled_status: 'N',
        decoupled_time: '',
        transtype: ""
      }
      return {
        ...state,
        responseRegisterNewMerchantIsLoaded: false,
        responseRegisterNewMerchant: null,
        isUpdatedWithBrands: false,
        newRegister: aux
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_CLOSE_MODAL_REGISTER: {
      return {
        ...state,
        responseRegisterNewMerchant: null,
        showModalStatusRegister: false
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_FILTERS:
      return {
        ...state,
        fieldsFiltereds: action.filters
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_GENERATE_BRANDS_INITIAL: {
      const aux = { ...state.newRegister };
      aux.brands = action.allBrands
      // console.log(aux.brands)
      // console.log(action)
      aux.brands = aux.brands.map(brand => {
        return {
          code_commerce: "",
          code: brand.code,
          name: brand.name,
          enabled: false,
          currencies: action.allCurrencies,
          whitelist_status: false,
          version: ""
        }
      })

      aux.brands = aux.brands.map(brand => {
        brand.currencies = brand.currencies.map(curren => {
          let obj = {
            numeric_code: curren.numeric_code,
            iso_code: curren.iso_code,
            enabled: 'false',
          }
          action.allCurrencies.forEach(e => {
            if (e.iso_code === obj.iso_code && e.numeric_code === obj.numeric_code) obj.description = e.description
          })
          return obj;
        })
        return brand;
      })
      // console.log(aux)
      return {
        ...state,
        newRegister: aux,
        isUpdatedWithBrands: true
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_NEW_REGISTER_FIELDS: {
      const aux = { ...state.newRegister };
      console.log(aux);
      // console.log(aux.brands)
      // console.log(action.newBrandUpdated)
      // action.newBrandsUpdated
      aux.brands.forEach((brand) => {
        if (brand.position === action.newBrandUpdated.position) {
          console.log(brand, "brandSelected")
          if (brand.enabled)
            brand.enabled = false;
          else
            brand.enabled = true;
        }
        return brand;
      })
      return {
        ...state,
        newRegister: aux
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_HANDLE_FILE: {
      let aux = { ...state.fileUploaded };
      // console.log(action.file)
      aux.file = action.file

      if (aux.file.type !== "application/vnd.ms-excel" && aux.file.type !== "text/csv") aux = { file: {} }

      return {
        ...state,
        fileUploaded: aux,
        isCorrectFormat: (action.file && (action.file.type === "application/vnd.ms-excel" || action.file.type === "text/csv")) ? true : false,
        sentFromInput: true
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RESET_CORRECT_FORMAT:
      return {
        ...state,
        isCorrectFormat: null,
        sentFromInput: false
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_GET_MERCHANT_INFO:
      // console.log("traer info d ecomercio", action)
      // console.log(state.responseGetMerchantInfo)
      return {
        ...state,
        allBrands: action.brands,
        allCurrencs: action.currencies,
        isLoadedMerchant: true
      }

    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_GET_MERCHANT_INFO: {
      let aux = [...state.allCurrencs]
      aux = aux.map(e => {
        e.enabled = false
        return e;
      })
      // console.log(aux)
      const brandsAux = []
      delete action.response.action;
      delete action.response.success;
      delete action.response.merchant;
      delete action.response.code;
      action.response.brandsAux = brandsAux
      // console.log(state)
      action.response.brandsAux = state.allBrands.map(elemt => {
        return {
          code_commerce: "",
          code: elemt.code,
          name: elemt.name,
          enabled: false,
          currencies: aux,
          whitelist_status: false,
          version: ""
        }
      })
      action.response.brandsAux = action.response.brandsAux.map(e => {
        action.response.brands = action.response.brands.map(k => {
          // k.currencies = k.currencies.map(i => {
          if (e.code === k.code) e.name = k.name;
          if (k?.currencies?.length && k.currencies[0] && k.currencies[0].iso_code === "PEN") {
            k.currencies.reverse()
          }
          return k;
        })
        action.response.brands.forEach(j => {
          if (e.code === j.code && j.enabled === true) {
            let copyCurrenciesEnabled = [...j.currencies]
            copyCurrenciesEnabled = copyCurrenciesEnabled.map(item => {
              e.currencies.forEach(obj => {
                if (item.iso_code === obj.iso_code) {
                  item.description = obj.description || '';
                }
              })
              return item;
            })
            console.log(e)
            console.log(j)
            j.currencies = copyCurrenciesEnabled;
            e = j;
          }
          if (e.enabled && e.currencies.length === 1) {
            console.log(state.allCurrencs)
            state.allCurrencs.forEach(k => {
              console.log(k)
              if (k.iso_code !== e.currencies[0].iso_code) e.currencies.push(k)
              e.currencies.splice(2, 1);
              // })
              return k;
            })
          }
        })
        return e
      })
      action.response.state = action.response.state ? 'Afiliado' : 'Desafiliado'
      // console.log(action)
      return {
        ...state,
        responseGetMerchantInfoIsLoaded: true,
        responseGetMerchantInfo: action.response,
        // newRegister: action.response
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_EDIT_UPDATE_ALL_BODY: {
      // console.log(action)
      let aux = { ...action.body }
      if (action?.body) {
        aux.brandsAux =  aux?.brandsAux?.map(e => {
          e.currencies = e?.currencies?.map(j => {
            j.description = ""
            action.currencies.forEach(k => {
              if (j.iso_code === k.iso_code && j.numeric_code === k.numeric_code) {
                console.log(k)
                j.description = k.description
              }
            })
            return j;
          })
          return e;
        })
      }
      console.log(aux)
      return {
        ...state,
        // responseGetMerchantInfo: 
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_BODY_NEW_MERCHANT: {
      // console.log(action)
      let aux = { ...action.body }
      if (action?.body) {
        aux.brands = aux?.brands?.map(e => {
          e.currencies = e.currencies.map(j => {
            j.description = ""
            action.currencies.forEach(k => {
              if (j.iso_code === k.iso_code && j.numeric_code === k.numeric_code) {
                console.log(k)
                j.description = k.description
              }
            })
            return j;
          })
          return e;
        })
      }
      console.log(aux)
      return {
        ...state,
        // responseGetMerchantInfo: 
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_EDIT_MERCHANT: {
      // console.log(action)
      // console.log(state.responseGetMerchantInfo)

      return {
        ...state,
        updateLoading: true,
        // responseGetMerchantInfo: aux
      }

    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_CURRENCIES_EDIT_MERCHANT:
      return {
        ...state,
        currenciesUpdatedEditMerchant: action.currencies,
        currenciesUpdatedEditMerchantLoaded: true
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_UPDATE_CURRENCIES_EDIT_MERCHANT:
      return {
        ...state,
        responseCurrenciesByEditMerchant: action.response
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_EDIT_MERCHANT:
      return {
        ...state,
        responseEditedMerchant: action.response,
        showModalConfirmUpdate: true,
        updateLoading: false
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_NEXT_PAGE:
      return {
        ...state,
        pageCurrent: state.pageCurrent + 1,
        changePage: true
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_BACK_PAGE:
      return {
        ...state,
        pageCurrent: state.pageCurrent - 1,
        changePage: true
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_GO_TO_PAGE: {
      let calculateNewPage;
      if (action.page > state.pageCurrent) calculateNewPage = action.page
      if (action.page < state.pageCurrent) calculateNewPage = state.pageCurrent - action.page

      return {
        ...state,
        isChangedByButtonNumber: true,
        pageCurrent: calculateNewPage,
        changePage: true
      }
    }
    // FIN

    //  BULK LOAD
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_CLEAN_ALL_FILTERS:
      return {
        ...state,
        filtersBulkLoad: {
          fromDate: '',
          toDate: '',
          fileName: '',
          stateBatch: '',
          stateProcess: ''
        }
      }

    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPLOAD_FILE:
      return {
        ...state,
        uploadling: true
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_UPLOAD_FILE: {
      let result = 100;
      if (action.response === 'undefined' || action.response === undefined || action.response.length === 0) result = 98
      return {
        ...state,
        responseUploadFile: action.response,
        uploadlingPercent: result,
        uploadFileCompleted: true,
        disabledUploadFileIsLoaded: (result === 100) && true,
        uploadling: false,
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_INCREASE_PERCENT: {
      return {
        ...state,
        uploadlingPercent: state.uploadlingPercent + action.quantity
      }
    }

    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RESET_VALUES:
      return {
        ...state,
        uploadlingPercent: 0,
        isCorrectFormat: false,
        sentFromInput: false,
        disabledUploadFileIsLoaded: true,
        responseGetUrlUploadFile: {},
        responseUploadFile: null,
        fileUploaded: {},
        responseGetUrlFile: null
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_GET_URL_UPLOAD_FILE:
      return {
        ...state,
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_GET_URL_UPLOAD_FILE: {
      console.log(action.response, "action.response")
      const aux = action.response ? action.response : {}
      const auxUrl = (action.response === null || action.response === undefined || action.response === "undefined" ||
        (action.response && action.response.meta && !action.response.success)) ? true : false
      console.log(aux, "action.response")
      console.log(auxUrl)
      return {
        ...state,
        responseGetUrlUploadFile: aux,
        disabledUploadFileIsLoaded: auxUrl
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_SENT_FILE_TO_SERVER:
      return {
        ...state
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_SENT_FILE_TO_SERVER: {
      console.log(action)
      return {
        ...state,
        responseFileSentToServer: action.response
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_HANDLE_VALUES_BULK_LOAD_FILTERS: {
      const aux = { ...state.filtersBulkLoad };
      aux[action.id] = action.value;

      return {
        ...state,
        filtersBulkLoad: aux
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_SENT_ALL_FILTERS:
      return {
        ...state,
        pageCurrentBulkLoad: action.page,
        responseGetListWithAllFilters: null

      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_SENT_ALL_FILTERS: {
      return {
        ...state,
        responseGetListWithAllFilters: action.response,
        getListWithAllFiltersIsLoaded: true,
        uploadFileCompleted: false,
        changePageBulkLoad: false,
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_PERCENT_LOADED:
      return {
        ...state,
        uploadlingPercent: action.percent,
        disabledUploadFileIsLoaded: action.percent === 100
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RESET_EDIT_MERCHANT_VALUES:
      return {
        ...state,
        isLoadedMerchant: false,
        showModalConfirmUpdate: false
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_DISABLED_BUTTON_UPLOAD:
      return {
        ...state,
        disabledUploadFileIsLoaded: action.value
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RESET_VALUES_BULK_LOAD: {
      console.log("MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RESET_VALUES_BULK_LOAD")
      return {
        ...state,
        getListWithAllFiltersIsLoaded: false,
        merchantsLoaded: false
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RECEIVE_RESPONSE_GENERATE_URL_DOWNLOAD_FILE:
      return {
        ...state,
        responseGetUrlFile: action.response
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_CLOSE_MODAL_EDIT_MERCHANT:
      return {
        ...state,
        showModalConfirmUpdate: false
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_RESET_VALUES_MERCHANTS_FILTERS: {
      const aux = {
        fromDate: '',
        toDate: '',
        merchantName: '',
        merchantCode: '',
        state: '',
        brand: ''
      }
      return {
        ...state,
        filter: aux,
        merchantsLoaded: false
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_DELETE_FILTERS: {
      const aux = { ...state.filter };
      console.log(action)
      console.log(aux)
      aux[action.propertyName] = ''
      return {
        ...state,
        filter: aux
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_BULK_LOAD_DELETE_FILTERS: {
      const aux = { ...state.filtersBulkLoad };
      console.log(action)
      console.log(aux)
      aux[action.propertyName] = ''
      return {
        ...state,
        filtersBulkLoad: aux
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_CHANGE_ORDER: {
      const aux = { ...state.filters }
      if (action.direction !== 'none') {
        aux.sort = action.direction+"_"+action.field;
      } else {
        aux.sort = '';
      }
      const orderBy = {
        field: action.field,
        direction: action.direction
      }

      return {
        ...state,
        orderBy: orderBy,
        changePage: false,
        // allUserLoaded: false,
        responseResultFilters: null,
        filters: aux
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_BULK_LOAD_NEXT_PAGE:
      return {
        ...state,
        pageCurrentBulkLoad: state.pageCurrentBulkLoad + 1,
        changePageBulkLoad: true
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_BULK_LOAD_BACK_PAGE:
      return {
        ...state,
        pageCurrentBulkLoad: state.pageCurrentBulkLoad - 1,
        changePageBulkLoad: true
      }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_BULK_LOAD_GO_TO_PAGE: {
      let calculateNewPage;
      if (action.page > state.pageCurrentBulkLoad) calculateNewPage = action.page
      if (action.page < state.pageCurrentBulkLoad) calculateNewPage = state.pageCurrentBulkLoad -action.page
      return {
        ...state,
        isChangedByButtonNumberBulkLoad: true,
        pageCurrentBulkLoad: calculateNewPage,
        changePageBulkLoad: true
      }
    }
    // FIN BULK LOAD
    default:
      return state
  }
}

export default merchants;